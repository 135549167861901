import { createStore } from "vuex";

export default createStore({
	state: {
		tokenTamu: localStorage.getItem("token_tamu") || null,
	},
	getters: {
		getTokenTamu(state) {
			return state.tokenTamu;
		},
	},
	mutations: {
		updateTokenTamu(state, token) {
			state.tokenTamu = token;
			if (token) {
				localStorage.setItem("token_tamu", token);
			} else {
				localStorage.removeItem("token_tamu");
			}
		},
	},
	actions: {
		updateToken({ commit }, token) {
			commit("updateTokenTamu", token);
		},
	},
	modules: {},
});
