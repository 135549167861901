import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import VueAxios from "vue-axios";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { LoadingPlugin } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueSignaturePad from "vue-signature-pad";

axios.defaults.withCredentials = true;

const app = createApp(App);

// axios.defaults.baseURL = "http://backend-admin.com:3001";
// app.config.globalProperties.$appUrl = "http://tamu.com:208";
// app.config.globalProperties.$appId = "5e7f2aa3-10cd-11ef-801e-ac120340b234";

// axios.defaults.baseURL = "http://backend-admin.local";
// app.config.globalProperties.$appUrl = "http://tamu.local";
// app.config.globalProperties.$appId = "00eb5d2b-314d-11ef-9288-d89ef328bfa0";

axios.defaults.baseURL = "https://localbackend-admin.sitepgri.com";
app.config.globalProperties.$appUrl = "https://tamu.sitepgri.com";
app.config.globalProperties.$appId = "a3d592a9-a95c-11ee-bc73-ae12ad127ca4";

app.use(store);
app.use(VueAxios, axios);
app.use(VueSweetalert2);
app.use(LoadingPlugin, {
    canCancel: false,
    color: "#002b80",
    backgroundColor: "#ccddff",
    opacity: 0.3,
    zIndex: 9999,
});
app.component("v-select", vSelect);
app.use(VueSignaturePad);

app.mount("#app");
