<template>
  <div class="overlay">
    <div class="container text-center col-11 col-xl-7 col-lg-8 col-md-9 h-100 rounded-3 py-3 shadow">
      <div class="rounded shadow py-2 form">
        <div>
          <h4 class="my-0">Formulir Buku Tamu</h4>
          <h2 class="my-0">SMK PGRI 1 KEDIRI</h2>
        </div>
      </div>
      <div v-if="verified" class="rounded shadow mt-3 p-2 form">
        <form @submit.prevent="simpanData">
          <div class="row mb-1 mb-sm-2">
            <label class="col-sm-3 col-form-label col-form-label-sm d-flex justify-content-sm-between gap-1">
              <span>Nama</span>
              <span>:</span>
            </label>
            <div class="col-sm-9">
              <input type="text" class="form-control form-control-sm" placeholder="Nama lengkap"
                v-model="formCreate.nama" required>
            </div>
          </div>
          <div class="row mb-1 mb-sm-2">
            <label class="col-sm-3 col-form-label col-form-label-sm d-flex justify-content-sm-between gap-1">
              <span>Jenis Instansi</span>
              <span>:</span>
            </label>
            <div class="col-sm-9">
              <v-select class="form-control form-control-sm p-0 border-0" v-model="formCreate.asal"
                placeholder="Pilih jenis instansi" :options="listAsal" :selectOnTab="true">
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!formCreate.asal" v-bind="attributes" v-on="events" />
                </template>
                <template #no-options>
                  Jenis Instansi tidak ditemukan.
                </template>
              </v-select>
            </div>
          </div>
          <div class="row mb-1 mb-sm-2">
            <label class="col-sm-3 col-form-label col-form-label-sm d-flex justify-content-sm-between gap-1">
              <span>Nama Instansi</span>
              <span>:</span>
            </label>
            <div class="col-sm-9">
              <input type="text" class="form-control form-control-sm" placeholder="Nama Organisasi, Perusahaan, dsb."
                v-model="formCreate.keterangan" required>
            </div>
          </div>
          <div class="row mb-1 mb-sm-2">
            <label class="col-sm-3 col-form-label col-form-label-sm d-flex justify-content-sm-between gap-1">
              <span>Alamat</span>
              <span>:</span>
            </label>
            <div class="col-sm-9">
              <textarea class="form-control form-control-sm" rows="2" v-model="formCreate.alamat"
                placeholder="Alamat lengkap instansi" required></textarea>
            </div>
          </div>
          <div class="row mb-1 mb-sm-2">
            <label class="col-sm-3 col-form-label col-form-label-sm d-flex justify-content-sm-between gap-1">
              <span>Kontak</span>
              <span>:</span>
            </label>
            <div class="col-sm-9">
              <input type="text" class="form-control form-control-sm" placeholder="Nomor telepon yang bisa dihubungi"
                v-model="formCreate.kontak" required pattern="[0-9]{9,13}">
            </div>
          </div>
          <div class="row mb-1 mb-sm-2">
            <label class="col-sm-3 col-form-label col-form-label-sm d-flex justify-content-sm-between gap-1">
              <span>Tujuan</span>
              <span>:</span>
            </label>
            <div class="col-sm-9">
              <v-select class="form-control form-control-sm p-0 border-0" v-model="formCreate.tujuan"
                placeholder="Pilih bagian yang dituju" :options="listTujuan" :selectOnTab="true">
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!formCreate.tujuan" v-bind="attributes" v-on="events" />
                </template>
                <template #no-options>
                  Tujuan tidak ditemukan.
                </template>
              </v-select>
            </div>
          </div>
          <div class="row mb-1 mb-sm-2">
            <label class="col-sm-3 col-form-label col-form-label-sm d-flex justify-content-sm-between gap-1">
              <span>Keperluan</span>
              <span>:</span>
            </label>
            <div class="col-sm-9">
              <textarea class="form-control form-control-sm" rows="2" v-model="formCreate.keperluan"
                placeholder="Jelaskan keperluan" required></textarea>
            </div>
          </div>
          <div class="row mb-1 mb-sm-2">
            <label class="col-sm-3 col-form-label col-form-label-sm d-flex justify-content-sm-between gap-1">
              <span>Foto</span>
              <span>:</span>
            </label>
            <div class="col-sm-9 text-start">
              <input type="file" ref="fileInput" class="form-control form-control-sm" placeholder="Ambil foto"
                accept="image/*" @change="handleInputFile" required>
              <img v-if="previewImage" :src="previewImage" style="max-width: 100%; max-height: 140px;"
                class="mt-1 shadow" alt="Preview">
            </div>
          </div>
          <div class="row mb-1 mb-sm-2">
            <label class="col-sm-3 col-form-label col-form-label-sm d-flex justify-content-sm-between gap-1">
              <span>Tanda Tangan</span>
              <span>:</span>
            </label>
            <div class="col-sm-9">
              <div class="signature-container p-0">
                <vue-signature-pad ref="signaturePad" class="form-control form-control-sm p-0" width="300px"
                  :options="{ onEnd }">
                </vue-signature-pad>
                <button v-if="formCreate.ttd" class="btn btn-sm btn-outline-danger tombol-hapus" type="button"
                  @click="clearSignature">
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-trash-fill"
                    viewBox="0 0 16 16">
                    <path
                      d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-3"></div>
            <div class="col-sm-9 text-start">
              <button type="submit" class="btn btn-success shadow-sm px-4">Simpan</button>
            </div>
          </div>
        </form>
      </div>
      <div v-else class="rounded shadow mt-3 p-2 form">
        <h6 class="text-center">Selamat Datang di SMK PGRI 1 KEDIRI.</h6>
        <p>Silakan masukkan token yang diberikan oleh petugas untuk mengisi buku tamu digital.</p>
        <form @submit.prevent="verifikasiToken">
          <div class="col-12 col-sm-10 col-md-8 col-lg-6 mx-auto">
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Masukkan Token" v-model="inputToken" required>
              <button class="btn btn-outline-secondary" type="submit">Verifikasi</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { VueSignaturePad } from 'vue-signature-pad';
import imageCompression from 'browser-image-compression'
export default {
  name: 'BukuTamu',
  components: {
    VueSignaturePad,
  },
  data() {
    return {
      listAsal: [
        "Organisasi", "Perusahaan", "Lembaga", "Masyarakat", "Instansi Pendidikan", "Lainnya"
      ],
      listTujuan: [
        "Kepala Sekolah", "Kurikulum", "Kesiswaan", "Humas", "Sarpras", "Bendahara", "Tata Usaha", "Lainnya"
      ],
      formCreate: {
        nama: null,
        asal: null,
        keterangan: null,
        alamat: null,
        kontak: null,
        tujuan: null,
        keperluan: null,
        foto: null,
        ttd: null,
      },
      previewImage: null,
      inputToken: null,
      verified: false
    }
  },
  mounted() {
    if (localStorage.getItem('token_tamu')) {
      this.verifikasiToken()
    }
  },
  computed: {
    tokenTamu() {
      return this.$store.getters.getTokenTamu
    }
  },
  watch: {

  },
  methods: {
    async verifikasiToken() {
      let memuat = this.$loading.show({
        loader: 'spinner'
      });
      try {
        const response = await this.axios.post('/tamu/verifikasi-token', {
          token_tamu: this.inputToken || localStorage.getItem('token_tamu')
        });
        if (response.data) {
          this.verified = true;
          localStorage.setItem('token_tamu', response.data.valid_token);
          this.$store.dispatch('updateToken', response.data.valid_token);
          this.inputToken = null;
        }
      } catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            if (this.inputToken) {
              this.$swal({
                title: 'Gagal',
                text: 'Token tidak valid! Silakan menghubungi petugas untuk mendapatkan token.',
                icon: 'error',
                confirmButtonText: 'Baik'
              })
            }
            this.verified = false;
            this.inputToken = null;
            this.$store.dispatch('updateToken', null);
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    async handleInputFile(event) {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1024,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(file, options);
        this.previewImage = URL.createObjectURL(compressedFile);
        this.formCreate.foto = new File([compressedFile], 'foto' + compressedFile.type.replace(/image\//, "."), { type: compressedFile.type });
      } else {
        this.$swal({
          title: 'Peringatan',
          text: 'File yang Anda pilih bukan file Gambar. Silahkan pilih jenis file yang sesuai!',
          icon: 'warning',
          confirmButtonText: 'Mengerti',
        }).then(() => {
          this.$refs.fileInput.value = null;
          this.previewImage = null;
        })
      }
    },
    onEnd() {
      const { data } = this.$refs.signaturePad.saveSignature();
      this.formCreate.ttd = data;
    },
    clearSignature() {
      this.$refs.signaturePad.clearSignature();
      this.formCreate.ttd = null;
    },
    async simpanData() {
      let memuat = this.$loading.show({
        loader: 'spinner'
      });
      if (this.formCreate.ttd) {
        const formData = new FormData();
        formData.append('token_tamu', this.tokenTamu);
        formData.append('nama', this.formCreate.nama);
        formData.append('asal', this.formCreate.asal);
        formData.append('keterangan', this.formCreate.keterangan);
        formData.append('alamat', this.formCreate.alamat);
        formData.append('kontak', this.formCreate.kontak);
        formData.append('tujuan', this.formCreate.tujuan);
        formData.append('keperluan', this.formCreate.keperluan);
        formData.append('foto', this.formCreate.foto);

        const dataURL = this.formCreate.ttd;

        const byteString = atob(dataURL.split(',')[1]);
        const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
        const arrayBuffer = new ArrayBuffer(byteString.length);
        let uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
          uint8Array[i] = byteString.charCodeAt(i);
        }

        const ttdBlob = new Blob([uint8Array], { type: mimeString });
        const ttdFile = new File([ttdBlob], 'ttd.png', { type: mimeString });
        formData.append('ttd', ttdFile);

        try {
          const response = await this.axios.post('/tamu/isi-buku', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          if (response.data) {
            this.$swal({
              title: 'Sukses',
              text: response.data.message,
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              this.$refs.fileInput.value = null;
              this.previewImage = null;
              this.formCreate = {
                nama: null,
                asal: null,
                keterangan: null,
                alamat: null,
                kontak: null,
                tujuan: null,
                keperluan: null,
                foto: null,
                ttd: null,
              }
              this.clearSignature();
              this.verified = false;
              this.$store.dispatch('updateToken', null);
            });
          } else {
            this.$swal({
              title: 'Gagal',
              text: 'Internal Server Error',
              icon: 'error',
              confirmButtonText: 'Baik',
            })
          }
        } catch (err) {
          if (err.response) {
            if (err.response.status == 403) {
              this.$swal({
                title: 'Gagal',
                text: 'Token tidak valid! Silakan menghubungi petugas untuk mendapatkan token.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.verified = false;
                this.$store.dispatch('updateToken', null);
              });
            } else {
              this.$swal({
                title: 'Gagal',
                html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            }
          } else {
            console.log(err)
          }
        }
      } else {
        alert('Tanda tangan diperlukan!');
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    }
  }
}
</script>

<style scoped>
.signature-container {
  position: relative;
  width: 300px;
}

.tombol-hapus {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 4;
}
</style>