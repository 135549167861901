<template>
  <main>
    <BukuTamu />
    <div class="slider">
      <div v-for="i in images" :key="i" class="slide">
      </div>
    </div>
  </main>
</template>

<script>
import BukuTamu from '@/components/BukuTamu.vue'
export default {
  name: 'App',
  components: {
    BukuTamu
  },
  data() {
    return {
      images: [],
      durationPerSlide: 2,
      transitionDuration: 1
    }
  },
  mounted() {
    this.getBackground();
  },
  methods: {
    async getBackground() {
      try {
        const response = await this.axios.post('/tamu/latar-belakang', {
          appId: this.$appId
        });
        if (response.data) {
          this.images = response.data.latar_belakang;
          this.animateBackground();
        } else {
          console.log('Latar belakang tidak ditemukan');
        }
      }
      catch (err) {
        console.log(err);
      }
    },
    animateBackground() {
      const transitionDurationPercentage = this.transitionDuration / ((this.durationPerSlide + this.transitionDuration) * this.images.length) * 100;

      const keyframes = `@keyframes slider {
        0%, ${Math.round(100 / this.images.length - transitionDurationPercentage)}%, 100% {
          transform: translateX(0);
          animation-timing-function: ease;
        }
        ${Math.round(100 / this.images.length)}% {
          transform: translateX(-100%);
          animation-timing-function: step-end;
        }
        ${Math.round(100 - transitionDurationPercentage)}% {
          transform: translateX(100%);
          animation-timing-function: ease;
        }
      }`;
      const sliders = `.slider .slide {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        animation: slider ${(this.durationPerSlide + this.transitionDuration) * this.images.length}s infinite;
      }`

      const styleSheet = document.styleSheets[0];
      styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
      styleSheet.insertRule(sliders, styleSheet.cssRules.length);

      let d = 0;
      let e = 1;
      for (const i of this.images) {
        const slideNth = `.slider .slide:nth-child(${e++}) {
          background-image: url(${i.url_gambar});
          animation-delay: ${d}s;
        }`;
        styleSheet.insertRule(slideNth, styleSheet.cssRules.length);
        d -= (this.durationPerSlide + this.transitionDuration);
      }
    }
  }
}
</script>

<style>
main {
  font-family: 'Trebuchet MS', sans-serif;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
}

.overlay .container {
  background: rgba(255, 255, 255, 0.5);
  position: relative;
  padding: 20px;
  overflow-y: auto;
}

.overlay .container .form {
  background: rgba(255, 255, 255, 0.25);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  position: relative;
  z-index: 3;
}

.overlay .container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  top: 60px;
  left: 30px;
  bottom: 60px;
  right: 30px;
  background-image: url('./assets/logo.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.5;
}

.slider {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;
}

#cari {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" fill="gray" class="bi bi-search" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: 7px;
  background-position-y: center;
  text-indent: 21px;
  background-size: 16px;
}

::-webkit-input-placeholder {
  font-style: italic;
}

:-moz-placeholder {
  font-style: italic;
}

::-moz-placeholder {
  font-style: italic;
}

:-ms-input-placeholder {
  font-style: italic;
}

.v-select {
  --vs-line-height: 1.36;
  --vs-border-radius: .25rem;
  --vs-controls-size: .75;
  --vs-border-style: none;
  --vs-border-width: 1px;
  --vs-border-style: solid;
  --vs-border-color: #dee2e6;
  --vs-search-input-placeholder-color: #595c5f;
  --vs-font-size: .875rem;
}

.reset-button {
  background: transparent;
  border: 1px solid #dee2e6;
  fill: rgba(60, 60, 60, 0.5);
  cursor: pointer;
}

.select-wrapper {
  position: relative;
  display: inline-block;
}
</style>
